import AlpineMagicHelpers from '../_snowpack/pkg/alpine-magic-helpers.js';
import Alpine from '../_snowpack/pkg/alpinejs.js';

window.Alpine = Alpine;
window.AlpineMagicHelpers = AlpineMagicHelpers;

Alpine.start();

const env = document.querySelector('body').dataset.env;

// Check that service workers are supported
if ('serviceWorker' in navigator && env === 'production') {
  // use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    try {
      navigator.serviceWorker.register('/sw.js');
    } catch (error) {
      console.error('Service worker registration failed: ', error);
    }
  });
}

// Toggle Mobile Menu
let openMenuButton = document.getElementById('openMobileMenu');
let closeMenuButton = document.getElementById('closeMobileMenu');
let mobileMenu = document.getElementById('mobileMenu');

openMenuButton.onclick = function () {
  mobileMenu.classList.toggle('hidden');
};

closeMenuButton.onclick = function () {
  mobileMenu.classList.toggle('hidden');
};
